import Vue from 'vue'
import App from './App.vue';
import Amplify from '@aws-amplify/core';
// import './registerServiceWorker';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
 import mixins from './mixins/utils.js' 
 Vue.mixin(mixins)   

// ST
// Amplify.configure({
//  Auth: {
//   region: "ap-northeast-1",
//   userPoolId: "ap-northeast-1_9yB5eOZqq",
//   userPoolWebClientId: "12dmq73hulcqkbibenemlujcb8",
//   },
//  API: {
//   endpoints: [
//   {
//      name: "v1",
//      endpoint: "https://2uera1k42i.execute-api.ap-northeast-1.amazonaws.com/v1",
//   }
//  ]
//  }
// });

//GO
Amplify.configure({
 Auth: {
  region: "ap-northeast-1",
  userPoolId: "ap-northeast-1_Zi2pOalST",
  userPoolWebClientId: "7esoeqa0s60j87a8cfiksofndr",
  },
 API: {
  endpoints: [
  {
     name: "v1",
     endpoint: "https://s6qq28w3dd.execute-api.ap-northeast-1.amazonaws.com/v1/",
  }
 ]
 }
});

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');